import React from 'react';
import { graphql } from 'gatsby';

import { BrowseAllContainer } from '../containers/browse-all';

const BrowseAllLocation = ({ data, pageContext }) => {
  const { slug, locations, category, subcategory, transit } = pageContext;
  const {
    articles: { nodes },
    ads: { articlesAdUnits },
    bannerAd: { bannerAd },
  } = data;

  return (
    <BrowseAllContainer
      slug={subcategory}
      nodes={nodes.filter(n => n.subcategories)}
      locations={locations}
      category={category}
      transit={transit}
      ads={articlesAdUnits}
      bannerAd={bannerAd}
    />
  );
};

export const query = graphql`
  query BrowseAllLocation(
    $subcategory: String
    $category: String
    $transit: String
  ) {
    articles: allContentfulArticle(
      sort: { fields: publishDate, order: DESC }
      filter: {
        subcategories: {
          elemMatch: { slug: { eq: $subcategory, nin: ["archive"] } }
        }
        categories: { elemMatch: { slug: { eq: $category } } }
        places: {
          elemMatch: { routes: { elemMatch: { system: { eq: $transit } } } }
        }
      }
    ) {
      nodes {
        ...ArticleListFragment
        heroImage {
          fluid(maxWidth: 1280, maxHeight: 717, resizingBehavior: FILL) {
            src
          }
        }
      }
    }

    ads: contentfulHomePage {
      ...ArticlesAdsFragment
    }

    bannerAd: contentfulHomePage {
      ...BannerAdFragment
    }
  }
`;

export default BrowseAllLocation;
